import TextField from '@mui/material/TextField';
import { FunctionComponent } from 'react';

interface Props {
  placeholder: string;
}

export const URLTextField: FunctionComponent<Props> = ({ placeholder }) => {
  return (
    <div>
      <TextField
        id="link_field"
        label="URL"
        placeholder={placeholder}
      ></TextField>
    </div>
  );
};
