import { URLTextField } from '../../components/URLTextField';
import { Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import axios from 'axios';
import './UrlFetch.css';
import { VideoPicker } from '../../components/VideoPicker';
import { CircularProgress } from '@mui/material';
import { Backdrop } from '@mui/material';
import { BASE_URL, ENDPOINTS } from '../../constants/endpoint';

/**
 * Component which acts as the page.
 */
const UrlFetch: FunctionComponent = () => {
  const url = `${BASE_URL}/${ENDPOINTS.METADATA}`;
  const [metadata, setMetadata] = useState({
    //State to see if metadata arrived from the server.
    data: '' as any,
    loading: true,
  });
  const [open, setOpen] = useState(false); //State of the spinner to know when needs to appear or not.
  const capitalizeString = (s: string) =>
    s.charAt(0).toUpperCase() + s.slice(1); //Simple function to capitalize.
  /**
   * Function for the download button. It starts the download process and gets back the metadata.
   */
  const handleClick = async () => {
    let input_value = (
      document.getElementById('link_field') as HTMLInputElement
    ).value;
    if (input_value === '') {
      alert('Error: Empty value not allowed');
      return;
    }
    setMetadata({ data: '', loading: true });
    setOpen(true);
    await axios
      .get(url, { params: { url: input_value } })
      .then((res) => {
        setOpen(false);
        setMetadata({ data: res.data, loading: false });
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };
  /**
   * This function gets called from the submit child button which gives back the file chosen in the file picker. Then we call the upload endpoint and get back the metadata.
   * @param file File (or video) to check with ffprobe.
   */
  const handleSubmit = async (file: File) => {
    const url = `${BASE_URL}/${ENDPOINTS.UPLOAD}`;
    const formData = new FormData();
    formData.append('uploadedVideo', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    setMetadata({ data: '', loading: true });
    setOpen(true);
    axios
      .post(url, formData, config)
      .then((res) => {
        setOpen(false);
        setMetadata({ data: res.data, loading: false });
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };
  return (
    <>
      <div className="urlfetch">
        <URLTextField placeholder="Insert URL" />
        <Button
          variant="contained"
          onClick={handleClick}
        >
          Fetch Data
        </Button>
      </div>
      <div className="video-picker-container">
        <VideoPicker getFile={handleSubmit} />
      </div>
      {metadata.loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="tables">
          <table>
            <tbody>
              <tr>
                <th>Codec Name: </th>
                {metadata.data.old_codec !==
                  metadata.data.streams[0].codec_name ? (
                  <td>
                    <span className="green_span">
                      {metadata.data.streams[0].codec_name.toUpperCase()}
                    </span>{' '}
                    (
                    <span className="red_span">
                      {metadata.data.old_codec.toUpperCase()}
                    </span>
                    )
                  </td>
                ) : (
                  <td className="green_span">
                    {metadata.data.streams[0].codec_name.toUpperCase()}
                  </td>
                )}
              </tr>
              <tr>
                <th>Codec Long Name: </th>
                <td>{metadata.data.streams[0].codec_long_name}</td>
              </tr>
              <tr>
                <th>Video Extension: </th>
                {metadata.data.old_extension !== 'mp4' ? (
                  <td>
                    <span className="green_span">MP4</span>(
                    <span className="red_span">
                      {metadata.data.old_extension}
                    </span>
                    )
                  </td>
                ) : (
                  <td className="green_span">MP4</td>
                )}
              </tr>
              <tr>
                <th>Profile: </th>
                <td>{metadata.data.streams[0].profile}</td>
              </tr>
              <tr>
                <th>Codec Type: </th>
                <td>{capitalizeString(metadata.data.streams[0].codec_type)}</td>
              </tr>
              <tr>
                <th>Codec Timebase: </th>
                <td>{metadata.data.streams[0].codec_time_base}</td>
              </tr>
              <tr>
                <th>Codec Tag String: </th>
                <td>{metadata.data.streams[0].codec_tag_string}</td>
              </tr>
              <tr>
                <th>Codec Tag: </th>
                <td>{metadata.data.streams[0].codec_tag}</td>
              </tr>
              <tr>
                <th>Width: </th>
                <td>{metadata.data.streams[0].width}</td>
              </tr>
              <tr>
                <th>Height: </th>
                <td>{metadata.data.streams[0].height}</td>
              </tr>
              <tr>
                <th>Coded Width: </th>
                <td>{metadata.data.streams[0].coded_width}</td>
              </tr>
              <tr>
                <th>Coded Height: </th>
                <td>{metadata.data.streams[0].coded_height}</td>
              </tr>
              <tr>
                <th>Sample Aspect Ratio: </th>
                <td>{metadata.data.streams[0].sample_aspect_ratio ? metadata.data.streams[0].sample_aspect_ratio : '/'}</td>
              </tr>
              <tr>
                <th>Display Aspect Ratio: </th>
                <td>{metadata.data.streams[0].display_aspect_ratio ? metadata.data.streams[0].display_aspect_ratio : '/'}</td>
              </tr>
              <tr>
                <th>Pixel Fmt: </th>
                <td>{metadata.data.streams[0].pix_fmt}</td>
              </tr>
              <tr>
                <th>Level: </th>
                <td>{metadata.data.streams[0].level}</td>
              </tr>
              <tr>
                <th>Color Range: </th>
                <td>{metadata.data.streams[0].color_range ? metadata.data.streams[0].color_range : '/'}</td>
              </tr>
              <tr>
                <th>Color Space: </th>
                <td>{metadata.data.streams[0].color_space ? metadata.data.streams[0].color_space : '/'}</td>
              </tr>
              <tr>
                <th>Is AVC: </th>
                <td>{metadata.data.streams[0].is_avc}</td>
              </tr>
              <tr>
                <th>Nal Length Size: </th>
                <td>{metadata.data.streams[0].nal_length_size}</td>
              </tr>
              <tr>
                <th>AVG Framerate: </th>
                <td>{metadata.data.streams[0].avg_frame_rate}</td>
              </tr>
              <tr>
                <th>Duration: </th>
                <td>
                  {Math.floor(
                    parseFloat(metadata.data.streams[0].duration) * 100
                  ) / 100}{' '}
                  (Seconds)
                </td>
              </tr>
              <tr>
                <th>Bitrate: </th>
                <td>
                  {Math.floor(
                    (parseFloat(metadata.data.streams[0].bit_rate) / 1000000) *
                    100
                  ) / 100}{' '}
                  (Mbps)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default UrlFetch;
