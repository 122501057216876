import { ChangeEvent, FormEvent, FunctionComponent, useState } from 'react';

interface Props {
  getFile: (file: File) => void;
}

export const VideoPicker: FunctionComponent<Props> = ({ getFile }) => {
  const [file, setFile] = useState<File>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file) getFile(file);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          accept="video/*, .mkv"
          name="uploadedVideo"
          onChange={handleChange}
        />
        <button
          disabled={!file}
          type="submit"
        >
          Upload
        </button>
      </form>
    </div>
  );
};
